import React from 'react';
import {Link} from 'react-router-dom';

const linksInsights = [
    {title:'Performance Dashboard'},
    {title:'Conversations'},
    {title:'Agent Update'},
];

const InsightsMenu = () => {
    return (
        <>
            <aside className="side-bar sticky-top right">
                
                <div className="widget widget_categories">
                    {/* <div className="widget-title">
                        <h4 className="title">AI Agents</h4>
                    </div> */}
                    <ul>
                        {linksInsights.map((item, ind)=>(
                            <li className="cat-item" key={ind}><Link to={"#"}>{item.title}</Link></li>
                        ))}
                    </ul>
                </div>
                
            </aside>   
        </>
    );
};

export default InsightsMenu;