import React from "react";
import { IMAGES } from "../constants/theme";

const Home2BannerSmall = ({ open }) => {
  return (
    <>
      <div
        className="dz-bnr-inr style-1 text-center"
        style={{ backgroundImage: `url(${IMAGES.footerbg2})` }}
      >
        <div className="container">
          <div className="dz-bnr-inr-entry">
            <h1 style={{color: '#fff'}}>
            Performance Dashboard
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home2BannerSmall;
