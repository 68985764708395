import React, { useState } from "react";
import Home2BannerFaq from "../components/Home2BannerFaq";
import { useLocation } from "react-router-dom";

const TrainingData = () => {
  const body = document.querySelector("body");  
  body.setAttribute("data-theme-color", 'color_2'); 
  const [open, setOpen] = useState(false);
  const location = useLocation().pathname;
    return (
        <>
            <div className="page-content bg-white">

              <div className="main-bnr-three">
                <Home2BannerFaq open={setOpen} />
              </div>
                <section className="content-inner">
                    <div className="container">
                        <div className="row ">
                            <div className="col-xl-12 col-lg-12">
                                  
                                <div className="blog-single dz-card sidebar">
                                    
                                    <div className="dz-info m-b30">
                                        <div className="dz-post-text m-t0">
                                            <span>
                                            <h5>Membership and Joining</h5>
                                            <ul className="mx-3">
                                            <li>Q: What types of memberships do you offer? A: We offer several membership plans, including monthly, quarterly, and annual options, as well as discounted rates for students and seniors. We also have family memberships available.</li>
                                            <li>Q: How do I join the fitness club? A: You can sign up online or at our reception. We offer various membership options, including monthly payments or longer terms with discounts.</li>
                                            <li>Q: What is the minimum age to join the gym? A: The minimum age for membership is 16 years old.</li>
                                            <li>Q: Is a wellness and fitness induction required? A: Inductions are not compulsory but highly recommended for new members to familiarize themselves with our facilities and equipment.</li>
                                            <li>Q: What should I bring to my first visit? A: Bring a water bottle, a towel, comfortable workout clothes, and appropriate shoes. If you plan to use the pool, bring swimwear and goggles as well.</li>
                                            <li>Q: What's required for a Student Membership? A: Student Membership requires proof of full or part-time education status.</li>
                                            <li>Q: What are your membership prices? A: Here are our monthly membership prices:</li>
                                              <ul className="mx-3">
                                                <li>Adult  Monthly - £40 per month</li>
                                                <li>Adult Quarterly - £35 per month</li>
                                                <li>Adult Annual - £30 per month</li>
                                                <li>Student Monthly - £35 per month</li>
                                                <li>Student Quarterly - £30 per month</li>
                                                <li>Student Annual - £25 per month</li>
                                                <li>Student Monthly - £35 per month</li>
                                                <li>Student Quarterly - £30 per month</li>
                                                <li>Student Annual - £25 per month</li>
                                                <li>Family Membership - £85 per month</li>
                                                <li>Family Quarterly - £75 per month</li>
                                                <li>Family Annual - £60 per month</li>
                                              </ul>
                                            </ul>
                                            <p></p>

                                            <h5>Membership Management</h5>
                                            <ul className="mx-3">
                                            <li>Q: How do I access the gym? A: Upon signup, you'll receive a PIN or QR code for gym access. Use our app to generate a QR code for entry and exit. If you don't have a smartphone, contact the gym manager for alternative arrangements.</li>
                                            <li>Q: What if I forget my PIN or it doesn't work? A: You can reset your PIN online. If it doesn't work during staffed hours, ring the buzzer for assistance. Outside staffed hours, use our support chat or email.</li>
                                            <li>Q: Can I let others use my PIN? A: No, sharing your PIN is strictly forbidden and will result in membership termination.</li>
                                            <li>Q: Can members change their membership type? A: Yes, some membership types can be changed, but fees may apply. Details are available in the Member Area.</li>
                                            <li>Q: Can members freeze their membership? A: Yes, members can freeze their membership for any period using the online Member Area. During this time, they'll pay a reduced Monthly Membership Amount.</li>
                                            <li>Q: What is a Bundle Membership? A: A Bundle Membership allows members to select two additional gyms besides their home gym to access.</li>
                                            <li>Q: What are the perks of a Premium Membership? A: Premium Members can access 4 additional gyms, invite up to 4 friends per month, and receive discounts from our external partners.</li>
                                            </ul>
                                            <p></p>

                                            <h5>Payments and Finances</h5>
                                            <ul className="mx-3">
                                            <li>Q: When is the first payment taken? A: Your first payment is taken immediately upon joining to secure your membership. Subsequent payments occur on the same date each month.</li>
                                            <li>Q: What payment methods are accepted? A: We accept credit/debit cards for initial fees and set up Direct Debits for monthly payments.</li>
                                            <li>Q: How do I view my payment history? A: Access your payment history in the Member Area or app, where it's updated every 24 hours.</li>
                                            <li>Q: How do I apply a promo code? A: Enter your promo code during the online signup process after selecting your membership type and before entering your personal details.</li>
                                            </ul>
                                            <p></p>

                                            <h5>Freezing and Cancellations</h5>
                                            <ul className="mx-3">
                                            <li>Q: What is the cancellation policy? A: There's a 14-day cooling-off period after joining. After that, members can cancel at any time by stopping their Direct Debit.</li>
                                            <li>Q: Are refunds available if a member cancels? A: Proportionate refunds are available for cancellations within the 14-day cooling-off period.</li>
                                            <li>Q: Can the club terminate a membership? A: Yes, we may terminate a membership for non-payment or breach of Gym Membership Rules.</li>
                                            </ul>
                                            <p></p>

                                            <h5>Facilities and Amenities</h5>
                                            <ul className="mx-3">
                                            <li>Q: What are the club's opening hours? A: Many of our gyms are open 24/7. Check specific times for each location on our website.</li>
                                            <li>Q: What amenities do you offer? A: Our facilities include a swimming pool, sauna, steam room, café, and in most locations, ladies-only sections.</li>
                                            <li>Q: Are there lockers available? A: Yes, free-to-use lockers are available in the changing rooms. Bring your own padlock or purchase one on-site.</li>
                                            <li>Q: Is there parking available? A: Yes, we provide ample free parking for all members.</li>
                                            <li>Q: Does the gym ever close for maintenance? A: Yes, we may close for up to 2 days a year for maintenance. No refunds are given for these temporary interruptions.</li>
                                            </ul>
                                            <p></p>

                                            <h5>Classes and Training</h5>
                                            <ul className="mx-3">
                                            <li>Q: What classes are included with my membership? A: Most classes, including aerobics, Zumba, and spin classes, are included. Some specialized classes may have an additional fee.</li>
                                            <li>Q: How do I book a class or a session with a personal trainer? A: Book through our mobile app or Member Area. Our staff can assist you with the process.</li>
                                            <li>Q: Do you offer any beginner classes? A: Yes, we offer a range of beginner classes including yoga, pilates, and strength training.</li>
                                            <li>Q: Are personal training sessions included in the membership? A: No, Personal Training, Sports Therapy, and Massage Therapy are separate services not included in the membership agreement.</li>
                                            </ul>
                                            <p></p>

                                            <h5>Health, Safety, and Accessibility</h5>
                                            <ul className="mx-3">
                                            <li>Q: How do you ensure the gym is safe and clean? A: Our gyms feature 24/7 CCTV, safety stations, and are cleaned daily with deep cleaning sessions each evening.</li>
                                            <li>Q: Is there CCTV in the gym? A: Yes, we operate 24-hour CCTV security at all our gyms for safety and security purposes.</li>
                                            <li>Q: What facilities are available for people with disabilities? A: Our club is fully accessible with facilities for people with disabilities, including accessible workout machines, ramps, and restrooms.</li>
                                            <li>Q: How often do you update your exercise equipment? A: We typically update our exercise equipment every two to three years.</li>
                                            <li>Q: What's the club's liability for lost or damaged personal property? A: Our liability is limited to £500 for negligent acts causing loss, damage, or theft of member property.</li>
                                            </ul>
                                            <p></p>

                                            <h5>Guest and Non-Member Access</h5>
                                            <ul className="mx-3">
                                            <li>Q: How can non-members use the facilities or bring guests? A: Non-members can purchase day passes from vending machines or at reception. Members can bring guests who must be signed in and pay a fee.</li>
                                            <li>Q: What is your policy on bringing children to the club? A: Children can be added to your membership or brought as guests under supervision, depending on their age and the facilities being used.</li>
                                            </ul>
                                            <p></p>

                                            <h5>Feedback and Complaints</h5>
                                            <ul className="mx-3">
                                            <li>Q: How do I submit feedback or a complaint? A: Direct any feedback or complaints to our onsite management team or through our designated email. All issues are addressed promptly and thoroughly.</li>
                                            <li>Q: What if I appear in social media content I did not consent to? A: Contact us directly via the social media account to request removal, adhering to our privacy and consent policies.</li>
                                            </ul>
                                            <p></p>

                                            <h5>Legal and Agreement</h5>
                                            <ul className="mx-3">
                                            <li>Q: Can the Membership Agreement change? A: Yes, we may make reasonable changes to the Membership Agreement at any time. Members should check online regularly for updates.</li>
                                            <li>Q: What law governs the Membership Agreement? A: The agreement is governed by the law of England and Wales.</li>
                                            </ul>
                                            <p></p>

                                            <h5>Keepme Fit – Membership Agreement</h5>
                                            <h5>Introduction</h5>
                                            <p>This is an Agreement between you (the Member), and Keepme Fit Limited, a company registered in England and Wales (VAT Reg No. 334 6764 83), whose registered office is at 71-75 Shelton Street, Covent Garden, London, UK, WC2H 9JQ ("Keepme Fit", "us" or "we").</p>
                                            <p>By completing the joining process at our website and becoming a Member of Keepme Fit, you are accepting all the terms and conditions in this Membership Agreement. You should only join if you have read them and accept them.</p>
                                            <p></p>

                                            <h5>Definitions</h5>
                                            <ul className="mx-3">
                                            <li>Administration Fee: our fees raised for changing membership terms or arising out of any breach of the terms as set out on our website. </li>
                                            <li>Gym Membership Rules: the rules governing your conduct as set out on our website at https://www.ultimatefitnessclub.com/legal/gym-membership-rules/. </li>
                                            <li>Joining Fee: the amount notified to you as part of the website joining process as a one-off fee payable for membership. </li>
                                            <li>Massage Therapy: services provided directly to you which do not form part of this Membership Agreement and are subject to a separate agreement between you and the therapist. </li>
                                            <li>Member Area: the online portal for Gym members at www.ultimatefitnessclub.com/member-area </li>
                                            <li>Membership: your contractual relationship with us, which can be on various different terms, some of which are only available at certain gyms, as follows: </li>
                                              <ul className="mx-3">
                                              <li>Annual Membership: a membership that is paid upfront and had a fixed term of 12 months. o Bundle Membership: a membership giving access to more than one gym location. </li>
                                              <li>Standard Membership: a membership for one specified gym. </li>
                                              <li>Premium Membership: a membership that offers a range of benefits including access to multiple gym locations, the ability to bring different friends with you each calendar month, and a selection of discounts & perks. </li>
                                              <li>Off-Peak Membership: a membership that allows the holder to enter Keepme Fit during specified times of the day, currently Monday to Friday: 9:00-11:00; 14:00-16:00; 20:00-22:00; and Saturday & Sunday 10:00-17:00. </li>
                                              <li>Student Membership: Membership available to Students only, with a fixed duration, and subject to the separate student terms and conditions set out herein. </li>
                                              </ul>
                                            <li>Monthly Membership Amount: the amount you agree to pay each month for your Membership. Only applies to certain types of Membership. </li>
                                            <li>Membership Amount: the amount you agree to pay upfront for your fixed term Membership. Only applies to certain types of membership. </li>
                                            <li>Personal Training Sessions: sessions booked by you directly with an independent self-employed trainer which do not form part of this agreement and are not provided by Keepme Fit and are subject to a separate agreement between you and the individual. </li>
                                            <li>Sports Therapy: Services provided directly to you which do not form part of this membership and are subject to a separate agreement between you and the therapist.</li>
                                            </ul>

                                            <p></p>

                                            <h5>1. Starting your Membership</h5>
                                            <ul className="mx-3">
                                            <li>1.1 Our joining process is an online process. Full membership prices and options can be found on our website (www.ultimatefitnessclub.com), to be selected by you as part of the joining process. By completing the online joining process and becoming a Member of Keepme Fit, you are accepting all the terms and conditions in this Membership Agreement (insofar as they are applicable). You should only join if you have read them and accept them.</li>
                                            <li>1.2 This Membership Agreement starts once you have accepted the terms during the online joining process. By accepting the terms, you are agreeing to pay any applicable Joining Fee, Administration Fee, Membership Amount and Monthly Membership Amount. These are shown at the start of the joining process and again before you confirm your payment instruction.</li>
                                            <li>1.3 You may choose during the online joining process to start your Membership either immediately or one month from the date that you accept the terms. We may inform you during the online joining process that a waiting list is in operation in which case your Membership starts when we inform you by email.</li>
                                            <li>1.4 You agree to comply with the Gym Membership Rules and you agree to use the gym facilities and equipment in the proper manner. You must consult a member of staff if you are unsure. We are not liable for any injury you suffer through the incorrect use of our equipment or facilities.</li>
                                            <li>1.5 You confirm that you are at least 16 years old.</li>
                                            <li>1.6 You will be liable for any damage caused to our equipment or facilities through your negligent use.</li>
                                            <li>1.7 You agree to tell us immediately of any changes to your personal details, including contact information; this should be done in the online Member Area.</li>
                                            </ul>

                                            <p></p>

                                            <h5>2. Using the gym</h5>
                                            <ul className="mx-3">
                                            <li>2.1 You are entitled to access and to use the gym or gyms defined by your Membership until termination or suspension of that Membership pursuant to this Agreement.</li>
                                            <li>2.2 We will make reasonable endeavours to make available to you the rights and privileges of Membership of the gym or gyms you joined and have paid for. This includes access to the gym and gym equipment, changing areas, staff and independent self-employed trainers for general advice. We will make reasonable endeavours to communicate to you in advance if we are unable to make available to you the rights and privileges of Membership.</li>
                                            <li>2.3 Members with an Off-Peak Membership will be able to enter the gym during specified times only, as set-out in the Definition of Off-Peak Membership above.</li>
                                            </ul>

                                            <p></p>

                                            <h5>3. Changing your Membership</h5>
                                            <ul className="mx-3">
                                            <li>3.1 Some Membership types can be changed. Fees may apply. Details can be found online in the Member Area.</li>
                                            <li>3.2 If you make regular Monthly Membership Amount payments you may Freeze your Membership at any time and for any period, using the online Member Area. When your Membership is frozen, your PIN number will be deactivated and you will pay a reduced Monthly Membership Amount.</li>
                                            <li>3.3 If you have a discounted Membership and you change to a new Membership product, you will waive the right to the discount on the original Membership and pay the standard price on the new Membership.</li>
                                            <li>3.4 If you have a discounted Membership and your Membership is terminated, you will waive the right to the discount and pay the advertised Membership Amount or Monthly Membership Amount if you later choose to re-join Keepme Fit.</li>
                                            <li>3.5 If you benefit from a Bundle Membership you may select two gyms in addition to your home gym that you would like to be able to access. This can be done online in the Member Area. You may change your selections at any time, free of charge. These changes may take up to 20 minutes to come into effect.</li>
                                            <li>3.6 We reserve the right to change the access privileges of members with Membership packages that allow access to multiple gym locations.</li>
                                            <li>3.7 We may, with reasonable notice and at our discretion, close our premises for reasonable periods of time to carry out maintenance, repairs, refurbishment, cleaning or for other reasons outside of our control, including at least 2 days a year for necessary maintenance or other work. We will endeavour to reopen facilities as soon as is reasonably possible in these circumstances. You agree that you will not be eligible for any refund for the temporary interruption in services during the period.</li>
                                            </ul>

                                            <p></p>

                                            <h5>4. Managing your permissions and data</h5>
                                            <ul className="mx-3">
                                            <li>4.1 There are certain email and SMS communications which it is necessary for us to send to all members, in connection with their Membership. In order to do so we will process your personal data for the purposes of performing our contractual obligations to you under the terms of your Membership. You cannot opt out of these communications. You may opt out of email and SMS communications that we may otherwise send for promotional or marketing purposes, but if you do we cannot be held responsible for any loss incurred by you not receiving gym-related communications. All processing of your personal data will be in accordance with our Privacy, CCTV & Cookie policy, available at https://www.ultimatefitnessclub.com/privacy-cookie-policy/.</li>
                                            <li>4.2 For the safety and security of you, our other members and our staff, and for associated purposes of crime prevention and detection, we operate 24-hour CCTV security at all our gyms. The images will be captured, processed and retained in accordance with our separate Privacy, CCTV & Cookie policy, available at https://www.ultimatefitnessclub.com/privacy-cookie-policy/. Access to such footage will also be governed by the provisions of that policy.</li>
                                            <li>4.3 We will securely store personal data including membership information and recorded CCTV footage in accordance with our privacy policy which is available at https://www.ultimatefitnessclub.com/privacy-cookie-policy/</li>
                                            <li>4.4 You can contact us with any queries or concerns by emailing membership@ultimatefitnessclub.com from (or quoting) the email address that we hold for you.</li>
                                            </ul>

                                            <p></p>

                                            <h5>5. Special terms for Premium Memberships</h5>
                                            <ul className="mx-3">
                                            <li>5.1 As a Premium member, you may select 4 gyms that you would like to be able to access in addition to your home gym. This can be done online in the Member Area. You may change your selections at any time, free of charge; these changes will take up to 20 minutes to come into effect. You can choose and access any gym in our estate without changing your direct debit sum, however, as the price of a Premium Membership differs by gym, should you use a more expensive gym more frequently than your home gym within any month, you will receive a surcharge at the end of that month for the difference in price.</li>
                                            <li>5.2 As a Premium member, you can invite up to 4 friends a month to join you for a gym session. This can be done online in the 'Bring a friend' section in the Member Area. When you use the 'Bring a friend' benefit you confirm that you are bringing a friend to join your training session and agree to both comply with the Gym Membership Rules. You can only invite the same friend twice each month. Unused invitations can be cancelled up until they have been accepted. On the 1st of every month all invites will be refreshed and you will have 4 new invites to use. Any unused invites at the end of the month do not rollover to the following month.</li>
                                            <li>5.3 As a Premium member, you can benefit from a range of discounts & perks from a selection of our external partners. The partners are responsible for establishing a separate set of terms & conditions with you. Keepme Fit cannot be held responsible for the goods or services provided by these suppliers.</li>
                                            </ul>

                                            <p></p>

                                            <h5>6. Special terms for Student Memberships</h5>
                                            <ul className="mx-3">
                                              <li>6.1 You agree that to be eligible for a Student Membership you must be in full or part-time education and will provide proof of this status if requested.</li>
                                            </ul>

                                            <p></p>

                                            <h5>7. Payment</h5>
                                            <ul className="mx-3">
                                              <li>7.1 If your Membership has an initial Membership Amount fee, you agree to pay us the full Membership Amount by credit or debit card. You will be notified of any applicable Membership Amount at the end of the online joining process, and this amount will be confirmed to you by email.</li>
                                            <li>7.2 If your Membership has a recurring Monthly Membership Amount fee, you agree to maintain a Direct Debit instruction with your bank (or recurring international Credit Card payment for overseas members) for the Monthly Membership Amount. Your first Monthly Membership Amount is due on the date shown at the end of the online joining process (which will also be confirmed to you by email), and subsequent Monthly Membership payments will be due on the same day of each month until your Membership is cancelled.</li>
                                            <li>7.3 You agree to pay us the Monthly Membership Amount regardless of whether there is any temporary interruption in services during the period, foreseen or unforeseen. In circumstances where we are required to close or restrict facilities for any reason no refunds will be due.</li>
                                            <li>7.4 If any payment due from you is returned unpaid or not honoured you will pay us on demand an Administration Fee of £12.</li>
                                            </ul>
                                            
                                            <p></p>

                                            <h5>8. Ending your Membership</h5>
                                            <ul className="mx-3">
                                              <li>8.1 You have the right to cancel this Agreement within 14 days from the start of the Agreement without giving any reason, but you must let us know you wish to do so within the 14-day period. You may email us at membership@ultimatefitnessclub.com or contact us through the Member Area (using the email that you used during the online joining process and the unique personal identification number (PIN) that you were sent), or you may use the cancellation form at https://www.ultimatefitnessclub.com/legal/membership-agreement/.</li>
                                            <li>8.2 In case of dispute you must show that you cancelled the Agreement in accordance with the cancellation terms, but for these purposes it will be sufficient to show that you sent your communication concerning your exercise of the right to cancel before the cancellation period had expired.</li>
                                            <li>8.3 If you have requested an immediate membership start date and you cancel your Membership within the 14-day period, proportionate charges will be due. We will refund to you any Joining Fee, Membership Amount and Monthly Membership Amount you have paid us, but the refunded amount will be reduced by the higher of (a) the one-day pass rate (for your gym at the time you joined) and (b) an amount in proportion to the number of days between the start of your Membership and the date you communicated to us that you wanted to cancel. Refunds will be made no later than 14 days after the day on which we were informed about your decision to cancel, and will be made using the same means of payment you used for the initial transaction.</li>
                                            <li>8.4 After the 14-day cancellation period you may terminate your Membership at any time by cancelling the direct debit mandate with your bank. Your Membership will end with immediate effect, and no refund will be due.</li>
                                            <li>8.5 We will terminate this Agreement and your Membership with immediate effect if you do not pay any applicable Joining Fee, Administration Fee, Membership Amount or Monthly Membership Amount when it falls due.</li>
                                            <li>8.6 We may terminate this Agreement and cancel your Membership with immediate effect on notice to you if you breach any of the Gym Membership Rules. In this event you will no longer be able to access any of our facilities and we will not give any refund.</li>
                                            <li>8.7 If we do not terminate your Membership if you breach any of the Gym Membership Rules, or if we give you extra time to pay if you do not pay your Joining Fee, Administration Fee, Membership Amount or Monthly Membership Amount when it falls due, that will not prevent us from subsequently enforcing the terms of this Agreement.</li>
                                            <li>8.8 If you have a Membership that gives you access to a single specified gym, and that gym closes indefinitely, this Agreement will end and you will receive a pro-rata refund of your Membership Amount or most recently paid Monthly Membership Amount, as applicable.</li>
                                            <li>8.9 We may assign the benefit of this Agreement and our rights to a third party provided we give you notice and your rights under this Agreement will not be prejudiced.</li>
                                            </ul>

                                            <p></p>

                                            <h5>9. Additional services</h5>
                                            <ul className="mx-3">
                                              <li>9.1 Personal Training Sessions and "additional services" do not form part of this Agreement and are not provided by Keepme Fit Limited.</li>
                                            <li>9.2 Any "additional services" (including, for example, Personal Training Sessions, Sports Therapy and Massage Therapy) which you contract for or agree to pay for, do not form part of your Membership Agreement and the Terms & Conditions of this Agreement will not apply to them. You should be aware that if you enter into any agreement for "additional services", you are entering into an agreement with those individuals and not with us.</li>
                                            <li>9.3 We do not accept any liability for any losses, damage, personal injury or other loss caused by any negligent act or omission of those providing the "additional services" which are specifically excluded from the Membership Agreement and We do not accept any responsibility for the same save where precluded by law.</li>
                                            <li>9.4 We may provide induction services or personal reviews of your training needs. Such services are provided without additional charge.</li>
                                            </ul>

                                            <p></p>

                                            <h5>10. Member satisfaction surveys</h5>
                                            <ul className="mx-3">
                                            <li>10.1 From time to time we may choose to invite some of our members to participate in a survey of satisfaction. This invitation will usually be by e-mail.</li>
                                            <li>10.2 At our discretion member satisfaction survey participation may be associated with a prize draw offering cash prizes of varying amounts to participants. Members that respond to such an invitation by completing the satisfaction survey by the advertised closing date will be entered into the draw.</li>
                                            <li>10.3 Eligible participants will only be entered once into a particular draw. A winner will be chosen at random from all entrants to that draw within 90 days of the closing date. Winners will be contacted after the prize is drawn to arrange receipt of the prize.</li>
                                            </ul>

                                            <p></p>

                                            <h5>11. Other clauses</h5>
                                            <ul className="mx-3">
                                            <li>11.1 Only you, the person named in the online joining process, can benefit from this Agreement.</li>
                                            <li>11.2 If any of the terms of this Agreement are invalid, unenforceable or illegal the remaining terms can still be enforced.</li>
                                            <li>11.3 Keepme Fit Limited, its parent companies, its agents, employees and subcontractors are not liable for any loss, damage or theft of any of your property that you bring onto any of our premises. If such loss, damage or theft is caused by the negligent acts or omissions of Keepme Fit Limited or its agents, employees or subcontractors, our liability to you will be limited to £500.</li>
                                            <li>11.4 We may make reasonable changes to the Gym Membership Rules and the Membership Agreement at any time. It is your responsibility to check online at regular intervals for changes to these documents.</li>
                                            <li>11.5 This Agreement is governed by the law of England and Wales.</li>
                                            <li>11.6 You should print a copy of this Agreement for future reference.</li>
                                            </ul>

                                            <p></p>

                                            <h5>DIGNITY AT Keepme Fit</h5>
                                            <p>Whatever you want from a gym, we want you to find it at Keepme Fit. We think everyone deserves to reach their goals, be it to run a marathon or to run after the grandkids, furthering your career or just wanting to train somewhere great with your mates. We are committed to creating a safe, inclusive, and welcoming work and work-out environment which is free from discrimination. An environment where everyone who visits, works for, or does business with Keepme Fit is able to further their goals and is treated with dignity and respect. Put simply, Keepme Fit is a place to be you.</p>
                                            <p>We consider any act of harassment or bullying to be in direct conflict with our Company purpose, aims, culture and values as outlined in our Diversity, Inclusion and Equality Manifesto as well as a breach of our Member Rules. Bullying and harassment can have serious negative consequences for health and wellbeing, and we are all about promoting good health – how else will you reach your PB? That is why we will not tolerate harassment or bullying of any kind. All allegations of bullying and harassment, whoever it is reported by or about, will be promptly and impartially investigated and, where it is determined that harassment or bullying has occurred, appropriate corrective action will be taken in line with the relevant internal procedures.</p>
                                            <p>Any concerns raised under this policy will be treated with discretion and confidentiality to the extent that a fair and robust investigation process will allow. Furthermore, we will also not tolerate victimisation of a person for making allegations of harassment or bullying in good faith or when supporting someone to make such a complaint.</p>
                                            <p>We like to keep things simple at Keepme Fit, as a result, we have one policy to cover bullying and harassment of and by members, employees, self-employed PTs, contractors, agency staff and anyone else engaged to work at Keepme Fit, whether by direct contract or otherwise. Furthermore, this policy covers bullying and harassment both within our gyms and events related to Keepme Fit as well as some settings outside of Keepme Fit, for example, work-related social events or actions on social media.</p>
                                            <p>If you have any questions about this policy, please speak with your manager/ gym manager in the first instance.</p>
                                            <p>Contents Definitions: bullying, harassment & victimisation Examples of bullying and harassment What should I do if I think I am being bullied or harassed? How we will manage your complaint What happens if I am accused of bullying or harassment? Responsibilities</p>
                                            <p>Definitions: bullying, harassment & victimisation</p>
                                            <p>Bullying is offensive, intimidating, malicious or insulting behaviour, and/or an abuse or misuse of power that is meant to undermine, humiliate, or injure the person on the receiving end.</p>
                                            <p>Harassment is a form of unlawful discrimination. It is unwanted conduct related to a protected characteristic which includes sex, gender reassignment (or transgender status), race (which includes colour, nationality and ethnic or national origins), disability, sexual orientation, religion or belief, marriage and civil partnership, pregnancy or maternity and age.</p>
                                            <p>This unwanted conduct either has the purpose of, or is reasonably considered by the person on the receiving end to have the effect of violating their dignity or otherwise creating an intimidating, hostile, degrading, humiliating or offensive environment.</p>
                                            <p>Victimisation is subjecting a person to a detriment because they have, in good faith, complained (whether formally or otherwise) that someone has been bullying or harassing them or someone else, or supported someone to make a complaint or given evidence in relation to a complaint.</p>

                                            <p></p>

                                            <p>When considering the above definitions, it is important to note:</p>
                                            <ul className="mx-3">
                                            <li>* Conduct may be harassment whether the person behaving in that way intends to offend. Different people find different things acceptable, and everyone has the right to decide what behaviour is acceptable and to have their feelings respected by others.</li>
                                            <li>* A single incident can be harassment where behaviour that any reasonable person would realise it would be likely to offend without you having to make it clear in advance that behaviour of that type is not accepted, for example, sexual touching.</li>
                                            <li>* Where it may not be so clear that behaviour would be unwelcome to, or could offend someone, for example some jokes, flirting or asking someone out for a date, it may not be harassment in the first instance. If, however the behaviour continues after you have made it clear, that the behaviour is unacceptable it will likely constitute harassment.</li>
                                            <li>* Harassment may also occur where a person engages in unwanted conduct towards another because they perceive someone to have a protected characteristic (for example, a perception that they are gay or have a disability), when they actually don't. Similarly, harassment could take place where someone is bullied or harassed because of someone else they have an association with, for example if their partner is pregnant.</li>
                                            <li>* You may feel harassed even if the unwanted conduct is not directed towards you. For example, an employee is offended after they have overheard colleagues speaking about a member in a very derogatory or objectified manner.</li>
                                            <li>* Provided that you act in good faith, i.e., you genuinely believe that what you are saying is true, you have a right not to be victimised for making a complaint or doing anything in relation to a complaint of bullying or harassment. Keepme Fit will take appropriate action to deal with any alleged victimisation, which may include disciplinary or other remedial action against anyone found to have victimised you. Please be aware that making a complaint that you know to be untrue, or giving evidence that you know to be untrue, may lead to disciplinary or other appropriate remedial action being taken against you.</li>
                                            <li>* Serious bullying or harassment may amount to civil or criminal offences, e.g., a civil offence under the Protection from Harassment Act 1997 and criminal offences of assault.</li>
                                            </ul>

                                            <p></p>

                                            <p>Examples of bullying and harassment</p>
                                            <p>Sometimes it is hard to know whether someone's behaviour is inappropriate and our view of what is appropriate can depend on the place, context, the people involved and can change over time. Bullying and harassment could be physical, verbal, or non-verbal and can include a wide range of behaviour including through email, text message or social media. We have included a number of examples of behaviours that could constitute bullying or harassment but please note that the below list is illustrative and not exhaustive:</p>

                                            <p></p>

                                            <p>Physical</p>
                                            <ul className="mx-3">
                                            <li>Unwelcome physical contact including patting, pinching, stroking, kissing, hugging, fondling, or other inappropriate touching. </li>
                                            <li>Physical violence, including sexual assault. • Stalk, threaten, intimidate, or coerce someone.</li>
                                            </ul>

                                            <p></p>

                                            <p>Verbal</p>
                                            <ul className="mx-3">
                                            <li>The offer of rewards for going along with sexual advances, e.g., a job promotion or additional benefits/ services. </li>
                                            <li>Making threats or comments about someone's job security or gym membership without a fair or reasonable rationale. </li>
                                            <li>Intrusive questions about an individual's sex life, gender identity or transition. </li>
                                            <li>Comments regarding someone's appearance, weight, dress, sexuality, age, gender identity or transition, relationship status that are derogatory or objectifying or otherwise would make someone feel uncomfortable. </li>
                                            <li>Jokes, comments, or stories of a sexual nature or about an individual's age, race, gender, gender identity or transition, disability, sexual orientation, religion, or marital status. </li>
                                            <li>Repeated and unwanted flirting or social invitations for dates or physical intimacy. • Flirting with someone at a time that is considered inappropriate due to the context, setting, time or place. </li>
                                            <li>Unwanted or derogatory nicknames. • Refusal to use the appropriate pronoun or calling someone who has transitioned their previous name. </li>
                                            <li>Starting or sharing malicious rumours or insulting/ ridiculing someone.</li>
                                            </ul>

                                            <p></p>

                                            <p>Non-verbal</p>
                                            <ul className="mx-3">
                                            <li>The use of obscene or suggestive gestures. </li>
                                            <li>Whistling or leering. </li>
                                            <li>The exclusion of an individual because they have or are perceived to have, or are associated or connected with someone with a protected characteristic. </li>
                                            <li>The display or sharing of pictures, objects, or literature with sexual or racial overtones, are transphobic or derogatory even if not directed at any person. </li>
                                            <li>Isolation or non-cooperation with an individual or those that are close to them. </li>
                                            <li>Oppressive levels of supervision. </li>
                                            <li>Deliberately setting up someone to fail.</li>
                                            </ul>

                                            <p></p>

                                            <p>Acts of bullying and harassment which can also constitute a serious crime could also include: </p>
                                            <ul className="mx-3">
                                            <li>Threats of violence. </li>
                                            <li>Physical attack and sexual assault. </li>
                                            <li>Hate crimes i.e., racist, homophobic, or transphobic hostility. </li>
                                            <li>The sharing of private or sexual videos or imagery of another person without their consent. </li>
                                            <li>Sharing the previous name, gender history or trans identity of an individual without their consent, if the individual has a GRC.</li>
                                            </ul>

                                            <p>Whilst we would hope that such instances would not occur at Keepme Fit and consider that we have taken all reasonable actions to prevent such occurrences, in the instance that another's actions could constitute a serious crime, we would always encourage you to report the matter to the police and will cooperate with any police enquiries should you wish to press charges.</p>

                                            <p></p>

                                            <p>What should I do if I think I am being bullied or harassed?</p>
                                            <p>We would encourage anyone who believes that they have been bullied, harassed, or victimised to take the first step and share their experiences and concerns with us. We know this can be difficult but to facilitate this, we have robust procedures designed to support open conversations, dispute resolution and, where appropriate, we will take remedial action.</p>
                                            <p>In some cases, you may be able to sort out matters yourself, informally. For example, where the person may not be aware that their behaviour is unwelcome or upsetting to you or where your feelings about what you consider acceptable have changed over time. Whilst this doesn't mean that the behaviour is accepted, if you suspect that the individual doesn't realise the impact of their behaviours, we encourage you to raise this with them. During your discussion we would recommend that you highlight the impacts of their behaviour and ask them to stop in order to help them to understand the impact of their actions and agree to change it.</p>

                                            <p>If you are not sure how to approach this discussion informally yourself: </p>
                                            <ul className="mx-3">
                                            <li>For employees, your line manager or the People Team can help you in doing this.</li>
                                            <li>If you are a member, self-employed PT, or contractor, it may be appropriate to contact Keepme Fit Manager or your main Keepme Fit contact for further support.</li>
                                            </ul>

                                            <p>If, however, an informal approach does not resolve matters, or the situation is too serious to be dealt with yourself, informally, you can make a formal complaint in one of the following ways. </p>
                                            <ul className="mx-3">
                                            <li>Employees – please see the Company's internal grievance procedure. </li>
                                            <li>Members – please see Keepme Fit complaints process. </li>
                                            <li>Self-employed PTs/ Contractors / agency staff – please raise your concerns with your main Company contact or, if contracted by a third party, please refer to their own internal procedures.</li>
                                            </ul>

                                            <p>We will also ensure that where possible, you are able to outline your complaint to someone that you feel comfortable to, for example if you would prefer to discuss an incident of sexual harassment with someone of your own sex. Further arrangements may need to be made to facilitate this, but we will discuss this with you.</p>

                                            <p></p>

                                            <p>How we will manage your complaint</p>
                                            <p>Further details can be found in the appropriate policy but when managing formal complaints regarding bullying or harassment we will ensure:</p>
                                            <ul className="mx-3">
                                            <li>It is investigated promptly and thoroughly.</li> 
                                            <li>To outline expected timelines of any process and keep you updated on the general progress of the investigation. Subject to data protection requirements we will confirm our findings and any further recommendations. </li>
                                            <li>That discretion and confidentiality is maintained as much as a fair and thorough investigation will allow. Limited disclosures will need to be made on a need-to-know basis to facilitate a fair and robust investigation and to implement remedial action or manage any associated risks. • To implement reasonable safeguards whilst matters are investigated and in line with any investigation findings.</li>
                                            </ul>
                                            <p>We do understand that you may wish to remain anonymous and that the subject of your complaint is not made aware that concerns have been raised by you, however, it is unlikely that we will be able to conduct a robust and fair investigation without some limited disclosures and without putting the allegations to the individual concerned. Please do however talk to us about your concerns and we will work with you to agree an approach.</p>

                                            <p></p>

                                            <p>What happens if I am accused of bullying or harassment?</p>
                                            <p>If someone approaches you informally, please do not dismiss their concerns because you were only joking, or you think the individual is being too sensitive. Different people find different situations and conversations acceptable, and everyone has the right to have their feelings respected by others. If you have offended someone without intending to, the person concerned may be satisfied with an explanation, an apology, and an assurance that you will be careful in future not to behave in a way that you now know may cause them distress.</p>
                                            <p>If a formal complaint is made about your own behaviour, the matter will be fully investigated and dealt with in accordance with the appropriate Company procedure. We appreciate that it can be distressing to have a complaint levelled against you and no conclusions will be drawn, or permanent corrective action taken until an investigation has been conducted. We may however need to take steps to limit contact between you and the person raising the complaint and in more serious cases suspend access to our sites until a conclusion can be drawn, but this does not constitute remedial action. Such shortterm action would be in line with our commitment to implement reasonable safeguards.</p>

                                            <p>Depending on the findings of the investigation and in the event that we have a reasonable belief that bullying, or harassment has occurred, remedial action will be taken. This action will vary depend on the perceived seriousness of your actions and your relationship with Keepme Fit, but could include:</p>
                                            <ul className="mx-3">
                                            <li>Employees – disciplinary action or in more serious cases dismissal without notice. </li>
                                            <li>Members – membership warnings or the termination of your membership. </li>
                                            <li>Self-employed PTs / Contractors / agency staff – changes to your contract or the termination of your contract with the Company.</li>
                                            </ul>

                                            <p>In more serious cases, where bullying or harassment may constitute unlawful discrimination and allegations may give rise to other civil claims or criminal proceedings against you, these would proceed independently of any action taken by Keepme Fit.</p>
                                            <p>It is also extremely important that you do not subject an individual who has made a complaint against you or supported an individual in making a complaint or given evidence in relation to such a complaint to acts of victimisation. Where it is determined that an individual has been subject to victimisation this will likely result in further remedial action.</p>
                                            <p>In the event the complaint is not upheld we will provide further support, as appropriate, to ensure that both parties can continue or resume working and working-out at Keepme Fit.</p>

                                            <p></p>

                                            <p>Responsibilities</p>
                                            <p>Keepme Fit will:</p> 
                                            <ul className="mx-3">
                                            <li>Conduct regular reviews of our policies, processes and working methods to ensure they are free from unlawful discrimination and support a welcoming work and work our environment.</li> 
                                            <li>Review the outcomes of cases where complaints of bullying and harassment have been made to check that the proper procedures have been followed and implement changes in response to our learning. </li>
                                            <li>Periodically monitor our success in ensuring that Keepme Fit remains a safe and inclusive environment, where everyone can be themselves such as through the use of confidential surveys.</li>
                                            </ul>

                                            <p>We will however only be able to ensure everyone has dignity at Keepme Fit with your help which you can give by ensuring you:</p>
                                            <ul className="mx-3">
                                            <li>Treat everyone at Keepme Fit with dignity and respect; whether that's employees, members, standalone PTs, contractors, or visitors to site. </li>
                                            <li>Act in accordance with our member rules, Keepme Fit policies, and any reasonable request from an Keepme Fit employee. </li>
                                            <li>Consider how your own behaviour may affect others and rectifying that if necessary. Everyone will have their personal reasons for coming to Keepme Fit and we want to ensure that everyone can get the most out of their work out or working day. </li>
                                            <li>Are clear with others when you find their behaviour unacceptable, unless it should be obvious in advance that this would be the case </li>
                                            <li>Be considerate and do not dismiss others if they raise concerns about your behaviour informally to you </li>
                                            <li>Intervene if appropriate and it safe to do so, to stop or prevent harassment or bullying and give support to recipients. </li>
                                            <li>Report harassment or bullying in line with the appropriate procedure and provide support to Keepme Fit in the investigation of any complaint </li>
                                            <li>If a complaint of harassment or bullying is made please don't pre-judge or victimise the complainant or alleged harasser.</li>
                                            </ul>

                                            <p>Managers at Keepme Fit have a particular responsibility to:</p>
                                            <ul className="mx-3">
                                            <li>Set the right example by their own behaviour. </li>
                                            <li>Support the maintenance of a safe and supportive working and work-out environment in line with Company values and Equality, Diversity, and Inclusion Manifesto. </li>
                                            <li>Make sure that all members, employees, contractors, and visitors know what standards of behaviour that are expected of them and take corrective action as appropriate and in line with Keepme Fit policies and procedures where conduct is unsatisfactory. </li>
                                            <li>Take all concerns raised seriously and assume every claim of bullying, harassment or other inappropriate behaviour is legitimate and escalated appropriately. </li>
                                            <li>Ensure employees, members, self-employed PTs, and other contractors know how they can raise any concerns they have and never conceal or discourage someone from raising a legitimate concern. </li>
                                            <li>Ensure employees know what further support is available for them if they have been a victim or accused of, bullying or harassment and speak to the People Team if you need further advice.</li>
                                            </ul>
                                            <p>Keepme Fit Ltd, Registered in England & Wales, No. 334 6764 83, Registered Office: 71-75 Shelton Street, Covent Garden, London, UK, WC2H 9JQ</p>

                                            <p></p>


                                            <h5>Keepme Fit Rules</h5>

                                            <p>We get it. Rules can be a bit boring. But we just need to run a few things by you before we can send you out on your fitness journey.</p>
                                            <p>The first thing to say, is welcome to the club. We're glad to have you with us.</p>
                                            <p>For the club to function as one, we all need to buy in to a few basic principles:</p>
                                            <ul className="mx-3">
                                            <li>* Everyone in the club is allowed to know as much or as little about the gym, fitness, exercises, and complicated-looking machines. If you've never heard of Single-Leg Romanian Deadlifts, that's ok. If you could list 150 different quad exercises, that's ok too. We're open to sharing the space with gym keenos, first timers, people who are indifferent and everyone in between.</li>
                                            <li>* We never mock, giggle, snigger or eye roll at fellow club members.</li>
                                            <li>* We respect whether you want to work out on your own, with a mate or two, with your nan. You do you.</li>
                                            </ul>
                                            <p>You're now part of our club, there to motivate and support you, every step of the way. So however you want to enjoy the gym, do it in a way that's respectful to the rest of the club. Have a read below of some of the other things we ask of you to keep our club happy.</p>

                                            <p></p>

                                            <h5>General Behaviour Learning the ropes</h5>
                                            <ul className="mx-3">
                                            <li>* When you joined, we offered a comprehensive induction to teach you how to use the gym equipment. Our advice would be to make sure you have done this induction as we get that it's not always easy to figure out how to use the equipment, but don't worry, our super knowledgeable and friendly staff are always on hand to help. Bookings can be made via the members area on the Keepme Fit website.</li>
                                            <li>* Any members who are under 18 will receive an online induction, and this must be done before using the gym. It's worth remembering that your PIN or QR code will not be activated until you've completed the induction.</li>
                                            <li>* If you are a wheelchair user, disabled or have a health condition that affects your ability to enjoy the facilities, we require you to have completed an induction at one of our gyms to ensure your safety and run through how to safely use our facilities and equipment. Again, it's worth remembering that your PIN or QR code will not be activated until you've completed the induction.</li>
                                            <li>* Another super important reason to do the induction is that we cannot accept any liability for any claim of personal injury if you use the gym equipment having not undertaken an induction with one of our members of staff, so be sure to get on it.</li>
                                            </ul>

                                            <p></p>

                                            <h5>What to wear</h5>
                                            <ul className="mx-3">
                                            <li>* Clothing such as denim, boots, flip-flops/sandals, or work wear are not the right thing in our gyms simply because they're not safe or practical. Comfy exercise attire of your choice and suitable footwear is what we're after, and it's important to get this right to avoid being asked to leave the gym. Please note that all members are required to wear a top whilst training, again avoiding the potential of being asked to leave the gym.</li>
                                            </ul>

                                            <p></p>

                                            <h5>Keeping you safe</h5>
                                            <ul className="mx-3">
                                            <li>* It's super important to remember that you cannot use the gym whilst under the influence of alcohol, narcotics, tranquilizers or any medication or other substance which may affect your ability to exercise safely. If it seems like you're not in the right state to use the facilities properly, then we can ask you to leave.</li>
                                            <li>* To keep everyone safe, you must not block or interfere with the fire/emergency or alternative access doors, or tamper with the fire extinguishers (unless in a genuine emergency).</li>
                                            <li>* You should never take part in any exercise activity that may be potentially harmful to you or others including (but not limited to) sparring, boxing, martial arts or other forms of contact sport, unless under the supervision of one of the Personal Trainers (exceptions apply in some sites, please speak to your site general manager if you have any questions).</li>
                                            </ul>

                                            <p></p>

                                            <h5>Keeping our place clean</h5>
                                            <ul className="mx-3">
                                            <li>* Go ahead and use your towel on the gym floor, but please do not leave it on the equipment.</li>
                                            </ul>

                                            <p></p>

                                            <h5>Keeping your things safe</h5>
                                            <ul className="mx-3">
                                            <li>* We cannot accept responsibility for any loss or damage to personal property, so remember to keep an eye out for your own belongings when visiting the gym.</li>
                                            <li>* For safety reasons, we ask you not to bring bags onto the gym floor. Feel free to use the lockers provided, but please only use them for the duration of your training session, as they will be emptied at night and your items removed to lost property. Padlocks may also be broken to gain access and will not be replaced, so all the more reason to remember to take your stuff.</li>
                                            <li>* We'll always try to return your lost property, but unfortunately can't take responsibility for any item held there. No one wants their things donated to charity or destroyed, so make sure to claim them within a week.</li>
                                            </ul>

                                            <p></p>

                                            <h5>Other ground rules</h5>
                                            <ul className="mx-3">
                                            <li>* The Changing Rooms will not be available for use between the hours of 1 a.m. and 3 a.m. in the morning.</li>
                                            <li>* Please use the bike racks provided to park your bicycle. The charging/storing of e-scooters/bikes is not permitted on site as they pose a fire risk.</li>
                                            <li>* Finally, our gyms are to exercise in, not to sleep in. We love having you here, but please make sure to leave the premises when you're done with your workout, and we'll see you again next time. Also, a few of our sites are not 24/7 so you must leave the premises when asked to do so at closing time.</li>
                                            </ul>

                                            <p></p>

                                            <h5>Behaviours that can get you banned</h5>
                                            <ul className="mx-3">
                                            <li>* It's really important to use your own PIN number or QR code every time you visit the gym. Both are personal to you, so remember not to let anyone else use them. To keep you all extra safe, we monitor PIN usage by CCTV 24/7, and any misuse may result in memberships being terminated. If you want to find out more, please see our PIN Abuse Policy.</li>
                                            <li>* We want to make sure all our equipment is kept in good nick, so make sure weights are put back in their original place when you're finished, and please do not drop them on the floor. It's important to do this to make sure your membership isn't terminated.</li>
                                            <li>* Dignity and respect are one of the most important things to us at Keepme Fit so please never behave in an aggressive, abusive, anti-social or threatening manner. We consider any act of harassment, bullying or victimisation to be in direct conflict with our culture & values. Because of the importance of this we have a separate Dignity at Keepme Fit Policy and any breaches of this could result in termination of your membership immediately and we reserve the right to contact the police if we think it's necessary.</li>
                                            <li>* We have a great offering of personal trainers in our gyms but Keepme Fit is not the place to engage in any unauthorised personal training business with other members, and it can lead to membership termination if anyone is found doing this.</li>
                                            <li>* Smoking, including e-cigarettes, is not something we want in any area of the gym. Any member found to be breach of this rule may have their membership terminated.</li>
                                            </ul>

                                            <p></p>

                                            <h5>Photo and Videos</h5>
                                            <p>You are welcome to take photographs and video recordings on the gym floor for your own personal use. Just make sure to stick to these rules and any extra rules displayed on the gym floor:</p>
                                            <ul className="mx-3">
                                            <li>* Anyone who appears in your photographs or videos must be aware that you are filming them, so make sure to ask their permission first.</li>
                                            <li>* If you are taking photos or recording videos of yourself in our changing rooms, please make sure you don't capture images of another member.</li>
                                            <li>* If another member feels unhappy or uncomfortable that you are filming them and makes a complaint to us, we may ask you to show us any images which you have taken in the gym and to delete them if appropriate.</li>
                                            <li>* If a member of our team asks you to stop filming or taking photographs, please make sure you do.</li>
                                            <li>* Please make sure you never post photographs or videos you have taken on Keepme Fit premises on any social media platform with other identifiable people in them, without getting consent from the person concerned that they are happy for it to go onto social media.</li>
                                            </ul>

                                            <p></p>

                                            <h5>Class Schedule</h5>
                                            <p>
                                              <table style={{ fontSize: '13px' }}>
                                                <thead>
                                                  <tr>
                                                  <th>Monday</th>
                                                  <th>Tuesday</th>
                                                  <th>Wednesday</th>
                                                  <th>Thursday</th>
                                                  <th>Friday</th>
                                                  <th>Saturday</th>
                                                  <th>Sunday</th>
                                                  </tr>
                                                </thead>
                                                <tbody style={{ fontSize: '12px' }}>
                                                <tr>
                                                <td>6:00 AM - Bodyattack</td>
                                                <td>6:00 AM - Spin</td>
                                                <td>6:00 AM - Bodycombat</td>
                                                <td>6:00 AM - Bodybalance</td>
                                                <td>6:00 AM - Bodystep</td>
                                                <td>8:00 AM - Bodypump</td>
                                                <td>9:00 AM - Bodybalance </td>
                                                </tr>

                                                <tr>
                                                <td>8:00 AM - Yoga </td>
                                                <td>8:00 AM - Cxworx</td>
                                                <td>8:00 AM - Pilates</td>
                                                <td>8:00 AM - Spin</td>
                                                <td>8:00 AM - Yoga</td>
                                                <td>9:30 AM - Bodyattack</td>
                                                <td>10:30 AM - Bodypump</td>
                                                </tr>

                                                <tr>
                                                <td>10:00 AM - Bodypump</td>
                                                <td>10:00 AM - Bodystep</td>
                                                <td>10:00 AM - Bodypump</td>
                                                <td>10:00 AM - Bodycombat</td>
                                                <td>10:00 AM - Bodyattack</td>
                                                <td>11:00 AM - Bodycombat</td>
                                                <td>12:00 PM - Bodystep</td>
                                                </tr>

                                                <tr>
                                                <td>12:00 PM - Zumba</td>
                                                <td>12:00 PM - Bodyattack</td>
                                                <td>12:00 PM - Cxworx</td>
                                                <td>12:00 PM - Zumba</td>
                                                <td>12:00 PM - Bodypump</td>
                                                <td>12:30 PM - Grit Plyo</td>
                                                <td>1:30 PM - Grit Strength</td>
                                                </tr>

                                                <tr>
                                                <td>4:00 PM - Grit Strength</td>
                                                <td>4:00 PM - Grit Cardio</td>
                                                <td>4:00 PM - Grit Plyo</td>
                                                <td>4:00 PM - Grit Strength</td>
                                                <td>4:00 PM - Grit Cardio</td>
                                                <td>2:00 PM - Zumba</td>
                                                <td>3:00 PM - Zumba</td>
                                                </tr>

                                                <tr>
                                                <td>5:30 PM - Bodycombat</td>
                                                <td>5:30 PM - Bodypump</td>
                                                <td>5:30 PM - Bodyattack</td>
                                                <td>5:30 PM - Bodypump</td>
                                                <td>5:30 PM - Zumba</td>
                                                <td>3:30 PM - Cxworx</td>
                                                <td>4:30 PM - Bodyattack</td>
                                                </tr>

                                                <tr>
                                                <td>7:00 PM - Bodybalance</td>
                                                <td>7:00 PM - Zumba</td>
                                                <td>7:00 PM - Bodystep</td>
                                                <td>7:00 PM - Cxworx</td>
                                                <td>7:00 PM - Bodycombat</td>
                                                <td>5:00 PM - Bodybalance</td>
                                                <td>6:00 PM - Yoga</td>
                                                </tr>

                                                <tr>
                                                <td>8:30 PM - Pilates</td>
                                                <td>8:30 PM - Bodybalance</td>
                                                <td>8:30 PM - Yoga</td>
                                                <td>8:30 PM - Bodyattack</td>
                                                <td>8:30 PM - Pilates</td>
                                                <td>6:30 PM - Spin</td>
                                                <td>7:30 PM - Cxworx</td>
                                                </tr>
                                                </tbody>
                                                
                                              </table>
                                            </p>
                                            <p>This Schedule Provides A Good Mix Of Cardio, Strength, Flexibility, And High-Intensity Interval Training Classes Throughout The Week. It Includes Popular Les Mills Programs Like Bodypump, Bodyattack, Bodycombat, Bodystep, Bodybalance, Cxworx, And Grit, As Well As Other Popular Classes Like Zumba, Yoga, Pilates, And Spin.</p>
                                            </span>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </section>
            </div>
        </>
    );
};

export default TrainingData;