import axios from 'axios';
import ReactMarkdown from 'react-markdown';

class ActionProvider {
    constructor(createChatBotMessage, setStateFunc, createClientMessage, stateRef) {
        this.createChatBotMessage = createChatBotMessage;
        this.setState = setStateFunc;
        this.createClientMessage = createClientMessage;
        this.stateRef = stateRef;

        // Ensure handleUserMessage is bound to the instance
        this.handleUserMessage = this.handleUserMessage.bind(this);
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    setCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value}; ${expires}; path=/`;
    }

    generateRandomKey() {
        return Math.random().toString(36).substr(2, 16);  // Generate a random string
    }

    getOrCreateRandomKey() {
        const cookieName = 'randomKey';
        let key = this.getCookie(cookieName);
        if (!key) {
            key = this.generateRandomKey();
            this.setCookie(cookieName, key, 365);  // Set cookie for 1 year
        }
        return key;
    }

    async handleUserMessage(message) {
        try {
            const sender = this.getOrCreateRandomKey();

            const response = await axios.post(
                'https://poc-llm.palmchatbot.com/llm/generate/response/chat/completions',
                {
                    query: message,
                    sender: sender,
                    org_id: 'keepmefitclub',
                    stream: false,
                    agent: 'Web' // options are Web, Instagram, Facebook, Email
                },
                {
                    headers: {
                        'accept': 'application/json',
                        'file-type': 'txt',
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log(response, 'response');

            const botMessage = this.createChatBotMessage(
                <ReactMarkdown>{response.data.result.trim()}</ReactMarkdown>
            );

            this.setState((prev) => ({
                ...prev,
                messages: [...prev.messages, botMessage],
            }));
        } catch (error) {
            console.error('Error fetching response from OpenAI:', error.response.data);
        }
    }
}

export default ActionProvider;
