import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Vapi from '@vapi-ai/web';
import { Link } from "react-router-dom";
import './VapiButton.css';
import { triggerVapiButtonClick } from '../../redux/actions';

const VapiButton = forwardRef(({ vapiKey, vapiAgentId }, ref) => {
    const dispatch = useDispatch();
    const shouldTriggerVapiButtonClick = useSelector(state => state.shouldTriggerVapiButtonClick);

    const [callStatus, setCallStatus] = useState('Speak to our AI Agent Now');
    const [vapi, setVapi] = useState(null);

    useEffect(() => {
        const initializeVapi = () => {
            const newVapi = new Vapi(vapiKey);
            setVapi(newVapi);

            newVapi.on('speech-start', () => {
                console.log('Speech has started');
            });

            newVapi.on('speech-end', () => {
                console.log('Speech has ended');
            });

            newVapi.on('call-start', () => {
                setCallStatus('End call');
            });

            newVapi.on('call-end', () => {
                setCallStatus('Speak to our AI Agent Now');
            });

            newVapi.on('volume-level', (volume) => {
                console.log(`Assistant volume level: ${volume}`);
            });

            newVapi.on('message', (message) => {
                console.log(message, 'message');
            });

            newVapi.on('error', (e) => {
                console.log(e);
            });
        };

        initializeVapi();

        return () => {
            if (vapi) {
                vapi.stop();
            }
        };
    }, [vapiKey]);

    const handleButtonClick = () => {
        if (callStatus === 'Speak to our AI Agent Now') {
            setCallStatus('Connecting');
            vapi.start(vapiAgentId);
        } else if (callStatus === 'End call') {
            vapi.stop();
        }

        // Reset the trigger in the Redux store
        dispatch(triggerVapiButtonClick());
    };

    useImperativeHandle(ref, () => ({
        handleButtonClick
    }));

    useEffect(() => {
        if (shouldTriggerVapiButtonClick) {
            handleButtonClick();
        }
    }, [shouldTriggerVapiButtonClick]);

    return (
        <Link
            to={"#"}
            onClick={handleButtonClick}
            className={`btn btn-skew appointment-btn d-xl-block d-none vapi-button ${callStatus.replace(' ', '-').toLowerCase()}`}
        >
            <span><i className="fa-solid fa-phone move-lr text-primary"></i> {callStatus}</span>
        </Link>
    );
});

export default VapiButton;
