export const MenuListArray2 = [
    /* {
        title: 'Locations',
        classChange: 'sub-menu-down',
        content: [
            { title: 'London', to: '/' },
            { title: 'Manchester', to: '/' },
            { title: 'Bristol', to: '/' },
            { title: 'Edinburgh', to: '/' },
            { title: 'Glasgow', to: '/' },
            { title: 'Leeds', to: '/' },

        ],
    }, */
]