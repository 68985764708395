import React from 'react'
import { Link } from 'react-router-dom'
import { IMAGES } from '../constants/theme'

const serviceCards = [
    { icon: 'flaticon-exercise', title: 'Les Mills', subtitle: 'Unleash Your Potential with Les Mills Programs.' },
    { icon: 'flaticon-exercise-2', title: 'Wellness', subtitle: 'Refresh and Rejuvenate in Our Wellness Facilities' },
];
const serviceCards2 = [
    { icon: 'flaticon-man', title: 'Training', subtitle: 'Train with Top-Notch Gym Facilities' },
    { icon: 'flaticon-lifting', title: 'One-on-One', subtitle: 'Achieve More with Personal Training Sessions' },
    { icon: 'flaticon-athletics', title: 'Fitness', subtitle: 'Get Fit with Diverse and Dynamic Classes'  },
];
const AboutClasses = () => {
    return (
        <>

            <div className="col-xl-4 left-grid">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 wow fadeInUp" data-wow-delay="0.2s">
                        <div className="section-head style-1">
                            <h5 className="sub-title">Dive into Diverse Workouts</h5>
                            <h2 className="title">Group <span> Ex </span> & PT</h2>
                            <p className="p-r50">Discover our extensive class schedule, designed to fit all fitness levels and preferences. Enjoy energizing, beginner-friendly sessions from dawn to dusk, 7 days a week. Book easily via our mobile app or Member Area.</p>
                        </div>
                    </div>
                    {serviceCards.map((item, ind) => (
                        <div className="col-xl-12 col-md-6 wow fadeInUp" key={ind}>
                            <div className="icon-bx-wraper style-4 bg-white m-b30">
                                <div className="icon-bx icon-bg-white m-b20 align-items-center">
                                    <div className="icon-cell text-primary rounded-circle m-r10">
                                        <i className={item.icon}></i>
                                    </div>
                                    <h4 className="dz-title m-b10"><Link to="/pricing">{item.title}</Link></h4>
                                </div>
                                <div className="icon-content">
                                    <p className="m-b15">{item.subtitle}</p>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 m-b30 order-md-1 order-2">
                <div className="trainer wow fadeInUp" data-wow-delay="0.8s">
                    <img src={IMAGES.aboutPic} alt="" className="anm" data-speed-x="-2" data-speed-scale="-2" />
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 right-grid order-md-2 order-1">
                <div className="row">
                    {serviceCards2.map((item, index) => (
                        <div className="col-xl-12 col-lg-12 wow fadeInUp" key={index}>
                            <div className="icon-bx-wraper style-4 bg-white m-b30">
                                <div className="icon-bx icon-bg-white m-b20 align-items-center">
                                    <div className="icon-cell text-primary rounded-circle m-r10">
                                        <i className={item.icon}></i>
                                    </div>
                                    <h4 className="dz-title m-b10"><Link to="/pricing">{item.title}</Link></h4>
                                </div>
                                <div className="icon-content">
                                    <p className="m-b15">{item.subtitle}</p>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}

export default AboutClasses