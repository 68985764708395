import React from 'react'
import { IMAGES } from '../constants/theme'
import CountUp from 'react-countup'

const counterBox = [
    { title: 'Leads', title2: 'Generated', number:'239', icon:'fa fa-user-plus' },
    { title: 'Tours', title2: 'Booked', number:'124', icon:'fa fa-calendar' },
    { title: 'Trials', title2: 'Booked', number:'58', icon:'fa fa-clock' },
    { title: 'Calls', title2: 'Completed', number:'85', icon:'fa fa-phone' },
    { title: 'Emails', title2: 'Sent', number:'204', icon:'fa fa-envelope' },
    { title: 'WhatsApp', title2: 'Chats', number:'382', icon:'fab fa-whatsapp' },
    { title: 'Sales', title2: 'Closed', number:'114', icon:'fa fa-usd' },
    { title: 'Questions', title2: 'Answered', number:'496', icon:'fa fa-question-circle' },
]
const StatsCounter = () => {
    return (
        <div className="row align-items-start" style={{marginTop: '100px', paddingBottom: '0px'}}>
            <div className="col-lg-12 col-md-10">
                <div className="row counter-inner-3 wow fadeInUp" data-wow-delay="0.6s" style={{boxShadow: 'none'}}>
                    {counterBox.map((item, ind) => (
                        <div className="col-3 text-center" key={ind} style={{paddingBottom: '30px'}}>
                            <div className="counter-box" style={{border: '1px solid #ececec', borderRadius: '5px', padding: '20px 20px 5px 20px'}}>
                                <div className="icon-cell text-primary">
                                    <i className={item.icon}></i>
                                </div>
                                <h2 className="title counter text-secondary" style={{fontSize: '32px'}}><CountUp start={0} end={item.number} duration={10}/> </h2>
                                <p style={{lineHeight: '1.2em', fontSize: '14px', color: '#666'}}>{item.title}<br /><span>{item.title2}</span></p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default StatsCounter