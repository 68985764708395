import React, { useEffect, useState } from "react";
import Home2Banner from "../components/Home2Banner";
import AboutCounter from "../components/AboutCounter";
import AboutFacilities from "../components/AboutFacilities";
import { IMAGES } from "../constants/theme";
import AboutClasses from "../components/AboutClasses";
import Memberships from "../components/AboutMemberships";
import { useLocation } from "react-router-dom";

const Home2 = () => {
	const body = document.querySelector("body");
	body.setAttribute("data-theme-color", 'color_2');
	const [open, setOpen] = useState(false);
	const location = useLocation().pathname;

	useEffect(() => {
		localStorage.setItem("theme", "color_2");
		localStorage.setItem("themeInd", 1);
	}, [location]);
	return (
		<>
			<div className="page-content bg-white">

				<div className="main-bnr-three">
					<Home2Banner open={setOpen} />
				</div>

				<section className="section">
					<AboutCounter />
				</section>

				<section className="about-bx4 content-inner-2 m-b50" style={{ backgroundImage: ` url(${IMAGES.BgImage8})`, backgroundSize: " 100%", backgroundRepeat: "no-repeat", }} >
					<div className="container">
						<AboutFacilities />
					</div>
				</section>

				<section className="content-inner overflow-hidden" style={{ backgroundImage: ` url(${IMAGES.footerbg2})`, backgroundSize: " cover", backgroundRepeat: "no-repeat", }} >
					<div className="container">
						<Memberships />
					</div>
				</section>

				<section className="content-inner-2 service-wrapper1" style={{ backgroundImage: `url(${IMAGES.BgImage8})`, backgroundSize: ` 100%`, backgroundRepeat: " no-repeat", }} >
					<div className="container">
						<div className="row align-items-end">
							<AboutClasses />
						</div>
					</div>
				</section>

				<section className="call-action style-1 footer-action">
					<div className="container">
						<div className="inner-content wow fadeInUp" data-wow-delay="0.8s">
							<div className="row justify-content-between align-items-center">
								<div className="text-center text-lg-start col-xl-6 m-lg-b20">
									<h2 className="title">Stay Connected</h2>
									<p>
										Your gateway to exclusive updates and fitness tips.
									</p>
								</div>
								<div className="text-center text-lg-end col-xl-6">
									<form
										className="dzSubscribe"
										action="assets/script/mailchamp.php"
										method="post"
									>
										<div className="dzSubscribeMsg"></div>
										<div className="form-group mb-0">
											<div className="input-group mb-0">
												<div className="input-skew ">
													<input
														name="dzEmail"
														required="required"
														type="email"
														className="form-control"
														placeholder="Your Email Address"
													/>
												</div>
												<div className="input-group-addon">
													<button
														name="submit"
														value="Submit"
														type="submit"
														onClick={(e) => e.preventDefault()}
														className="btn btn-secondary btn-lg btn-skew"
													>
														<span>Connect</span>
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default Home2;
