// reducers.js
import { TRIGGER_VAPI_BUTTON_CLICK } from './constants';

const initialState = {
    shouldTriggerVapiButtonClick: false
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRIGGER_VAPI_BUTTON_CLICK:
            return {
                ...state,
                shouldTriggerVapiButtonClick: true
            };
        default:
            return state;
    }
};

export default rootReducer;
