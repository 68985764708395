// MessageParser.js
class MessageParser {
    constructor(actionProvider) {
        this.actionProvider = actionProvider;
    }

    parse(message) {
        console.log(message);
        this.actionProvider.handleUserMessage(message);
    }
}

export default MessageParser;
