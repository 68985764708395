import React from "react";
import Index from "./pages/Index";

//Css
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/css/style.css";

function App() {
	return (
		<>
			<Index />
		</>
	);
}

export default App;
