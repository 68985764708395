import React from "react";
import { IMAGES } from "../constants/theme";

const Home2Banner = ({ open }) => {
  return (
    <>
      <div
        className="banner-inner"
        style={{ backgroundImage: `url(${IMAGES.SliderBg2})` }}
      >
        <h2 className="data-text">
          <span>F</span>
          <span>I</span>
          <span>T</span>
          <span>N</span>
          <span>E</span>
          <span>S</span>
          <span>S</span>
        </h2>
        <div className="container">
          <div className="banner-content">
            {/* <h1 className="title">
              <span className="left anm wow fadeInUp">PRO</span>
              <span className="right anm wow fadeInUp">WORKOUTS</span>
            </h1> */}
            <div className="row wow fadeInUp" data-wow-delay="0.4s">
              <div className="col-4">
                <div className="bottom-content">
                  <p>
                  Whatever your fitness ambition, achieve it at Keepme Fitness Club. From marathon training to staying active with the grandkids, we're here to support your journey in a safe, inclusive, and welcoming environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="banner-media media1 anm wow fadeInUp">
            <img src={IMAGES.Slide2Hero} alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Home2Banner;
