import { createChatBotMessage } from 'react-chatbot-kit';
import QuickButtonsWidget from './QuickButtonsWidget'; // Adjust the import path as necessary
import ActionProvider from './ActionProvider'; // Adjust the import path as necessary

const botName = "Keepme Fit Club";


function getCookie(name) {
    let nameEQ = name + "=";
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(nameEQ) === 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    return null;
}

let randomKeyValue = getCookie('randomKey');

const config = {
    botName: botName,
    initialMessages: [
        createChatBotMessage(randomKeyValue ? `Welcome to Keepme Fit Club!` : `Welcome to Keepme Fit Club!`, {
        //     widget: 'quickButtons',
        }),
    ],
    customStyles: {
        botMessageBox: {
            backgroundColor: '#376B7E',
        },
        chatButton: {
            backgroundColor: '#5ccc9d',
        },
    },
    customComponents: {
        header: () => (
            <div className="react-chatbot-kit-chat-header" style={{ display: 'flex', alignItems: 'center', padding: '18px' }}>
                <img src="/favicon.png" alt="Profile" style={{ width: '24px', height: '24px', marginRight: '10px' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bold' }}>Keepme Fit Club</span>
                </div>
            </div>
        ),
        botAvatar: () => (
            <img src="/agent-olivia.png" alt="Bot Avatar" style={{ width: '36px', height: '36px', marginRight: '10px' }} />
        ),
    },
    // widgets: [
    //     {
    //         widgetName: 'quickButtons',
    //         widgetFunc: (props) => <QuickButtonsWidget {...props} />,
    //         mapStateToProps: ["actionProvider"],
    //     },
    // ],
    actionProvider: ActionProvider,
};

export default config;
