import React from 'react';
import {Link} from 'react-router-dom';
import { IMAGES } from '../constants/theme';
import Header2 from "../layouts/Header2";
import { useLocation } from "react-router-dom";

const ErrorPage = () => {
    let update = new Date();
    const location = useLocation().pathname;
    return (
        <>
            <div className="page-wraper">
                <Header2 />
                <div className="page-content bg-white">		
                    <section className="error-page" data-text="ERROR" style={{backgroundImage: "url("+ IMAGES.BgAppoint+")"}}>
                        <div className="container">
                            <div className="inner-content text-center" data-text="">
                                <div className="dz_error">404</div>
                                <h2 className="error-head">The page you are looking for cannot be found.</h2>
                                <Link to={"/"} className="btn btn-primary btn-skew"><span>BACK TO HOMEPAGE</span></Link>
                            </div>
                        </div>
                    </section>
                
                </div>
                <footer className="site-footer style-1 bg-img-fix footer-action" id="footer">
                    <div className="footer-bottom">
                        <div className="text-center"> 
                            <span className="copyright-text">Copyright © {update.getFullYear()}{" "}
								<Link to="/" rel="noreferrer" target="_blank">
									Keepme Fit Club
								</Link>
                            </span> 
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default ErrorPage;