import React from "react";
import { Link } from "react-router-dom";
import { IMAGES } from "../constants/theme";
import { useDispatch } from 'react-redux';
import { triggerVapiButtonClick } from '../redux/actions';


const serviceList = [
	{ title: "24/7 Access" },
	{ title: "Personalised Induction" },
	{ title: "Flexible Memberships" },
	{ title: "Multi-location Access" },
	{ title: "Swimming Pool" },
	{ title: "Sauna / Steam Room" },
	{ title: "Cutting-edge Facilities" },
	{ title: "Ladies-only Sections" },
	{ title: "Free Parking" },
	{ title: "Café" },
];
const AboutServices = () => {
	return (
		<>
			<div className="row align-items-center">
				<div className="col-lg-6">
					<div className="dz-media p-r20">
						<div className="image-box wow fadeInUp">
							<img src={IMAGES.aboutPic5} alt="" />
							<div className="tag">
								<h3>24/7</h3>
								<h5>Access</h5>
							</div>
						</div>
						<img src={IMAGES.aboutPic6} alt="" className="wow fadeInUp" />
					</div>
				</div>
				<AboutServicesDetails />
			</div>
		</>
	);
};

export default AboutServices;

export const AboutServicesDetails = () => {

	const dispatch = useDispatch();

	const handleTriggerClick = () => {
		dispatch(triggerVapiButtonClick());
	};


	return (
		<>
			<div className="col-lg-6 about-content m-lg-t40 wow fadeInUp">
				<div className="section-head style-1">
					{/* <h5 className="sub-title">About us.</h5> */}
					<h2 className="title mb-0">
						Our <span> Facilities </span>
					</h2>
					<p className="description m-b10">
						Your Ultimate Fitness Space
					</p>
				</div>
				<p>
					Enjoy our fully equipped, accessible facilities designed to accommodate all members, including those with disabilities.
				</p>
				<ul className="pr-list list-italic m-t30 m-b35">
					{serviceList.map((item, ind) => (
						<li key={ind}>
							<i className="flaticon-check-mark"></i>
							{item.title}
						</li>
					))}
				</ul>
				<Link
					to="/"
					className="btn btn-skew btn-lg btn-primary shadow-primary"
					onClick={handleTriggerClick}
				>
					<span>Book a Tour</span>
				</Link>
			</div>
		</>
	);
};
