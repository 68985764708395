import React, { useState, useEffect, useReducer, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { IMAGES } from "../constants/theme";
import VapiButton from "../components/VapiButton/VapiButton";
import Collapse from "react-bootstrap/Collapse";
import { MenuListArray2 } from "./MenuListArray2";
import Modal from 'react-modal';

// Required for screen reader accessibility
Modal.setAppElement('#root');

const Header2 = () => {
	/* for sticky header */
	const [headerFix, setheaderFix] = useState(false);
	const location = useLocation();
	const [activeMenu, setActiveMenu] = useState("");
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});

		// Check if the user is already logged in
		const storedLoginStatus = localStorage.getItem('isLoggedIn');
		if (storedLoginStatus === 'true') {
			setIsLoggedIn(true);
		} else {
			setIsModalOpen(true); // Show modal if not logged in
		}
	}, []);

	useEffect(() => {
		var mainMenu = document.getElementById("OpenMenu");
		if (mainMenu) {
			if (sidebarOpen) {
				mainMenu.classList.add("show");
			} else {
				mainMenu.classList.remove("show");
			}
		}
	});

	// Menu dropdown list
	const reducer = (previousState, updatedState) => ({
		...previousState,
		...updatedState,
	});
	const initialState = {
		active: "",
		activeSubmenu: "",
	};
	const [state, setState] = useReducer(reducer, initialState);
	const handleMenuActive = (status) => {
		setState({ active: status });
		if (state.active === status) {
			setState({ active: "" });
		}
	};
	const handleSubmenuActive = (status) => {
		setState({ activeSubmenu: status });
		if (state.activeSubmenu === status) {
			setState({ activeSubmenu: "" });
		}
	};

	function AddActiveMenu() {
		MenuListArray2?.forEach((ell) => {
			if (ell.to === location.pathname) {
				setActiveMenu(ell.title);
			}
			ell.content?.forEach((ele) => {
				if (ele.to === location.pathname) {
					setActiveMenu(ell.title);
				}
			});
		});
	}
	useMemo(AddActiveMenu, [location.pathname]);

	const handleLogin = () => {
		// Hardcoded credentials
		const hardcodedUsername = 'ian@keepmefit.club';
		const hardcodedPassword = 'Ej8H9NcnEwPZy-h';

		if (username === hardcodedUsername && password === hardcodedPassword) {
			setIsLoggedIn(true);
			setIsModalOpen(false);
			localStorage.setItem('isLoggedIn', 'true'); // Store login status in localStorage
		} else {
			alert('Invalid username or password');
		}
	};

	return (
		<>
			{/* Overlay Popup for Authentication */}
			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => setIsModalOpen(false)}
				shouldCloseOnOverlayClick={false}
				shouldCloseOnEsc={false}
				style={{
					overlay: {
						backgroundColor: 'rgba(0, 0, 0, 1)', // Solid black background
						zIndex: 10000000000,
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						height: '100%',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						padding: '20px',
						width: '400px',
						textAlign: 'center'
					}
				}}
			>
				<input
					type="text"
					placeholder="Username"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
					style={{ marginBottom: '10px', padding: '10px', width: '100%' }}
				/>
				<br />
				<input
					type="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					style={{ marginBottom: '10px', padding: '10px', width: '100%' }}
				/>
				<br />
				<button onClick={handleLogin} style={{ padding: '10px 20px', width: '100%', background: '#a00121', border: 'none', color: '#ffffff' }}>Login</button>
			</Modal>

			<header
				className="site-header mo-left header header-transparent style-2"
				style={{ zIndex: 100 }}
			>
				<div
					className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? "is-fixed" : ""
						}`}
				>
					<div className="main-bar clearfix">
						<div className="container clearfix">

							<div className="logo-header mostion logo-light">
								<Link to="/">
									<img src={IMAGES.logoWhite} alt="" />
								</Link>
							</div>

							<div className="logo-header mostion logo-dark">
								<Link to={"/"}>
									<img className="select_logo" src={IMAGES.logo} alt="" />
								</Link>
							</div>

							<button
								className={`navbar-toggler navicon justify-content-end ${sidebarOpen ? "open" : "collapsed"}`}
								type="button"
								onClick={() => setSidebarOpen(!sidebarOpen)}
							>
								<span></span>
								<span></span>
								<span></span>
							</button>

							<div className="extra-nav">
								<div className="extra-cell">
									{/* Show Vapibutton on home page only*/}
									{location.pathname === "/" && <VapiButton vapiKey="069df550-0776-4a20-8959-7ea05e7fab1f" vapiAgentId="6d32e147-2a62-405a-b517-9c246518cbe4" />}
									{location.pathname === "/" && <Link to="https://wa.me/+447460641250" className="btn btn-skew appointment-btn d-xl-block d-none">
										<span><i className="fab fa-whatsapp"></i></span>
									</Link>}
								</div>
							</div>

							<div
								id="navbarNavDropdown"
								className={`header-nav navbar-collapse collapse justify-content-end ${sidebarOpen ? "show" : ""
									}`}
							>
								<div className="logo-header logo-dark">
									<Link to={"/"}>
										<img src={IMAGES.logo} alt="" />
									</Link>
								</div>
								<ul className="nav navbar-nav navbar navbar-left">
									{MenuListArray2.map((item, index) => {
										let menuClass = item.classChange;
										if (menuClass !== "sub-menu-down") {
											return (
												<li
													className={`${menuClass} ${item.title === activeMenu ? "active" : ""
														}`}
													key={index}
												>
													<Link to={item.to}>{item.title}</Link>
												</li>
											);
										} else {
											return (
												<li
													className={`${menuClass} ${state.active === item.title ? "open active" : ""
														} ${item.title === activeMenu ? "active" : ""}`}
													key={index}
												>
													{item.content && item.content.length > 0 ? (
														<>
															<Link
																to={"#"}
																onClick={() => {
																	handleMenuActive(item.title);
																}}
															>
																{item.title}
															</Link>
															<Collapse
																in={state.active === item.title ? true : false}
															>
																<ul
																	className={`sub-menu ${menuClass === "mm-collapse" ? "open" : ""
																		}`}
																>
																	{item.content &&
																		item.content.map((data, index) => {
																			return (
																				<li
																					key={index}
																					className={`${state.activeSubmenu === data.title
																						? "open"
																						: ""
																						}`}
																				>
																					{data.content &&
																						data.content.length > 0 ? (
																						<>
																							<Link
																								to={data.to}
																								onClick={() => {
																									handleSubmenuActive(
																										data.title
																									);
																								}}
																							>
																								{data.title}
																								<i className="fa fa-angle-right" />
																							</Link>
																							<Collapse
																								in={
																									state.activeSubmenu ===
																										data.title
																										? true
																										: false
																								}
																							>
																								<ul
																									className={`sub-menu ${menuClass === "mm-collapse"
																										? "open"
																										: ""
																										}`}
																								>
																									{data.content &&
																										data.content.map(
																											(data, index) => {
																												return (
																													<>
																														<li key={index}>
																															<Link
																																to={data.to}
																															>
																																{data.title}
																															</Link>
																														</li>
																													</>
																												);
																											}
																										)}
																								</ul>
																							</Collapse>
																						</>
																					) : (
																						<Link to={data.to}>
																							{data.title}
																						</Link>
																					)}
																				</li>
																			);
																		})}
																</ul>
															</Collapse>
														</>
													) : (
														<Link to={item.to}>{item.title}</Link>
													)}
												</li>
											);
										}
									})}
								</ul>

								<div className="dz-social-icon">
									<ul>
										<li>
											<Link target="_blank" to="https://www.facebook.com/keepmefitclub">
												<i className="fab fa-facebook-f"></i>
											</Link>
										</li>
										<li>
											<Link target="_blank" to="https://www.instagram.com/keepmefitclub">
												<i className="fab fa-instagram"></i>
											</Link>
										</li>
										<li>
											<Link target="_blank" to="https://wa.me/+447460641250">
												<i className="fab fa-whatsapp"></i>
											</Link>
										</li>
									</ul>
								</div>

							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header2;
