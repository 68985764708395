import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import Home2BannerSmall from "../components/Home2BannerSmall";
import { IMAGES } from '../constants/theme';
import InsightsMenu from '../elements/InsightsMenu';
import InsightsCounter from "../components/InsightsCounter";
import { useLocation } from "react-router-dom";

const Insights = () => {
  const body = document.querySelector("body");  
  body.setAttribute("data-theme-color", 'color_2'); 
  const [open, setOpen] = useState(false);
  const location = useLocation().pathname;
    return (
        <>
            <div className="page-content bg-white">

              <div className="main-bnr-three">
                <Home2BannerSmall open={setOpen} />
              </div>
                <section className="content-inner">
                    <div className="container">
                        <div className="row ">
                            <div className="col-xl-8 col-lg-8">
                                  
                                <div className="blog-single dz-card sidebar">
                                    
                                    <div className="dz-info m-b30">
                                        {/* <h2 className="dz-title">Performance Dashboard</h2> */}
                                        <div className="icon-bx-wraper style-2">
                                            <div className="icon-bx" style={{height: '80px', width: '80px'}}>
                                                <span className="icon-cell">
                                                  <img src={IMAGES.agentLogo} alt="" />
                                                </span>
                                            </div>
                                            <div className="icon-content">
                                              <h3 className="dz-title">"Olivia"</h3>
                                              <p>AI Sales Agent</p>
                                          </div>
                                        </div>

                                        <div className="row align-items-start text-center" style={{marginTop: '50px'}}>
                                          <div className="col-md-4">
                                            <span class="fa-stack fa-3x mb-2">
                                              <i class="fa fa-circle fa-stack-2x text-primary"></i>
                                              <i class="fa fa-user-plus fa-stack-1x text-white"></i>
                                            </span>
                                            <div className="content">
                                              <h4 className="number">Leads</h4>
                                              <span className="text-success">107% <i className="fa-solid fa-arrow-up"></i></span>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <span class="fa-stack fa-3x mb-2">
                                              <i class="fa fa-circle fa-stack-2x text-primary"></i>
                                              <i class="fa fa-rocket fa-stack-1x text-white"></i>
                                            </span>
                                            <div className="content">
                                              <h4 className="number">Tours</h4>
                                              <span className="text-success">84% <i className="fa-solid fa-arrow-up"></i></span>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <span class="fa-stack fa-3x mb-2">
                                              <i class="fa fa-circle fa-stack-2x text-primary"></i>
                                              <i class="fa fa-bolt fa-stack-1x text-white"></i>
                                            </span>
                                            <div className="content">
                                              <h4 className="number">Trials</h4>
                                              <span className="text-success">78.5% <i className="fa-solid fa-arrow-up"></i></span>
                                            </div>
                                          </div>
                                        </div>

                                        <InsightsCounter />

                                        <div className="dz-post-text">
                                            <blockquote className="wp-block-quote" style={{marginTop: '0px'}}>
                                                <p>“ Significant improvement with a 107% increase in leads generated, 84% more tours booked, and a 78.5% rise in trials compared to last month. ”</p>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <InsightsMenu />
                            </div>
                        </div>
                    </div>   
                </section>
            </div>
        </>
    );
};

export default Insights;