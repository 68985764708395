import { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";

//layouts
import Footer2 from "../layouts/Footer2";
import Home from "./Home";
import Insights from "./Insights";
import Header2 from "../layouts/Header2";
import ErrorPage from "./ErrorPage";
import TrainingData from "./TrainingData";

function Index() {
	var baseName = "/";

	return (
		<BrowserRouter basename={baseName}>
			<Routes>
				<Route element={<MainLayout />}>
					<Route path="/" exact element={<Home />} />
					<Route path="/insights" exact element={<Insights />} />
					<Route path="/trainingdata" exact element={<TrainingData />} />
					<Route path="/error-404" exact element={<ErrorPage />} />
				</Route>
			</Routes>

		</BrowserRouter>
	);
}

function MainLayout() {
	return (
		<div className="page-wraper">
			<Header2 />
			<Outlet />
			<Footer2 />
		</div>
	);
}

export default Index;
